<template>
  <a-layout style="height: 100%">
    <a-layout-sider width="200" style="background: #fff; border-right: 1px solid #e8e8e8;padding: 1px 1px 1px 1px" breakpoint="lg">
      <a-menu
        mode="inline"
        :default-selected-keys="['1']"
        :default-open-keys="['sub1']"
        :style="{ height: '100%', borderRight: 0 }"
      >
        <a-sub-menu key="sub1">
          <span slot="title"><a-icon type="user" />subnav 1</span>
          <a-menu-item key="1">
            option1
          </a-menu-item>
          <a-menu-item key="2">
            option2
          </a-menu-item>
          <a-menu-item key="3">
            option3
          </a-menu-item>
          <a-menu-item key="4">
            option4
          </a-menu-item>
        </a-sub-menu>
        <a-sub-menu key="sub2">
          <span slot="title"><a-icon type="laptop" />subnav 2</span>
          <a-menu-item key="5">
            option5
          </a-menu-item>
          <a-menu-item key="6">
            option6
          </a-menu-item>
          <a-menu-item key="7">
            option7
          </a-menu-item>
          <a-menu-item key="8">
            option8
          </a-menu-item>
        </a-sub-menu>
        <a-sub-menu key="sub3">
          <span slot="title"><a-icon type="notification" />subnav 3</span>
          <a-menu-item key="9">
            option9
          </a-menu-item>
          <a-menu-item key="10">
            option10
          </a-menu-item>
          <a-menu-item key="11">
            option11
          </a-menu-item>
          <a-menu-item key="12">
            option12
          </a-menu-item>
        </a-sub-menu>
      </a-menu>
    </a-layout-sider>
    <a-layout style="padding: 0 0px 0 0px">
      <a-layout-content
        :style="{ background: '#fff', padding: '24px', margin: 0, minHeight: '280px' }"
      >
        <router-view />
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>

<script>
import VaiBasicLayout from './VaiBasicLayout'

export default {
  components: {
    VaiBasicLayout
  },
  data () {
    return {
      collapsed: true,
      title: 'HYDRO-BLOK'
    }
  }
}
</script>

<style lang="less" scoped>

.header {
  display: inline;
  padding: 0 24px;
  background-color: #f6f8fa;
  box-shadow: 1px 1px 0 0 #e8e8e8;
  -webkit-box-shadow: 1px 1px 0 0 #e8e8e8;
  border-bottom: 1px solid #e8e8e8;

  div {
    display: inline-block;
  }

  ul {
    display: inline-block;
  }

  .breadcrumb {
    margin: 0 0 0 12px;
  }

  .menu {
    margin: 0 0 0 12px;
    display: none;
  }

  .header-logo {
    img, svg {
      height: 36px;
      width: 36px;
      vertical-align: middle;
    }

    h1 {
      display: inline-block;
      // color: #fff;
      font-size: 20px;
      margin: 0 0 0 12px;
      font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
      font-weight: 600;
      vertical-align: middle;
    }
  }
}

</style>
